import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import styles from "./header.module.css"
import { graphql, useStaticQuery } from "gatsby"

function Header() {
  const queryResult = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 128, height: 64) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  const { file } = queryResult
  const { childImageSharp } = file
  const { fixed: image } = childImageSharp

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <Link to="/">
          <Img fixed={image} alt="Karpton Logo" />
        </Link>
      </div>
    </div>
  )
}

export default Header
