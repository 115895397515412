import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Header from "./header"

import styles from "./layout.module.css"

import "normalize.css"
import "../global.css"

function Layout({ children }) {
  return (
    <Fragment>
      <div className={styles.content}>
        <header>
          <Header />
        </header>
        <main>{children}</main>
      </div>
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

Layout.defaultProps = {
  children: null,
}

export default Layout
