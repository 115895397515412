import Helmet from "react-helmet"
import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

function Metadata({ pageTitle, pageDescription, keywords, authors }) {
  const queryResult = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)
  const { title, description } = queryResult.site.siteMetadata
  return (
    <Helmet defaultTitle={title} titleTemplate={`%s | ${title}`}>
      <html lang="en" />
      {pageTitle && <title>{pageTitle}</title>}
      <meta name="description" content={pageDescription || description} />
      {keywords.length > 0 && (
        <meta name="keywords" content={keywords.join(",")} />
      )}
      {authors.map(author => (
        <meta key={author} name="author" content={author} />
      ))}
    </Helmet>
  )
}

Metadata.propTypes = {
  pageTitle: PropTypes.string,
  pageDescription: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  authors: PropTypes.arrayOf(PropTypes.string),
}

Metadata.defaultProps = {
  pageTitle: null,
  pageDescription: null,
  keywords: [],
  authors: [],
}

export default Metadata
